(function($) {


vc_row_adjustments = {
    init: function () {
    	this._init_events();
    },

    _init_events: function () {
        $(window).load(function() {
            vc_row_adjustments._set_fixed_image_bg_height();
            vc_row_adjustments._set_fixed_image_bg_top();
            vc_row_adjustments._set_fixed_image_bg_mmenu_slideout();
            vc_row_adjustments._set_breadcrumbs_padding();
        });
    	$(window).on('weisang_resize_ready', function() {
            vc_row_adjustments._set_fixed_image_bg_height();
            vc_row_adjustments._set_fixed_image_bg_top();
            vc_row_adjustments._set_breadcrumbs_padding();
    	});
        $(document).on( 'scroll', function(){
            if ( $(window).scrollTop() > 200){
                setTimeout( function() { 
                    $('.vc_row.scroll_icon').addClass('scrolled');
                }, 500);
            }
        });
    },

    _set_fixed_image_bg_height: function () {
        var height = $(".vc_row.weisang_fixed_bg_header").outerHeight();
        $('[data-field="bg_fixed_image"]').css('height',height);
    },

    _set_fixed_image_bg_top: function () {
        $('[data-field="bg_fixed_image"]').removeClass('top_without_breadcrumbs top_with_breadcrumbs');
        if ($('.bbp-breadcrumb, .breadcrumbs').length > 0) {
            $('[data-field="bg_fixed_image"]').addClass('top_with_breadcrumbs');
            $('.page-with_breadcrumbs, [data-section="weisang_forum"]').css('padding-top',0);
        } else {
            $('[data-field="bg_fixed_image"]').addClass('top_without_breadcrumbs');
        }
    },

    // Set padding to main class depending on breadcrumbs
    _set_breadcrumbs_padding: function () {  
        if ($('.bbp-breadcrumb, .breadcrumbs').length > 0) {          
            var breadcrumbs_height = $('.bbp-breadcrumb, .breadcrumbs').outerHeight();
            $('main.main').css('padding-top', breadcrumbs_height);

            if (window.innerWidth <= 930) {
                $('.top_with_breadcrumbs').css('height', $('.weisang_fixed_bg_header').height() + breadcrumbs_height);
            } else {
                $('.top_with_breadcrumbs').css('height', $('.weisang_fixed_bg_header').height());
            }
        }
    },

    // Add mm-slideout class if using IE
    _set_fixed_image_bg_mmenu_slideout: function () {
        if ($('body').hasClass('weisang_is_ie')) {
            $('[data-field="bg_fixed_image"]').addClass('mm-slideout');
        }
    }
};


})(jQuery); 