(function($) {

sales_partners_grid = {

    init: function () {
        this._init_isotope();
        this._init_events();
        this._init_select_events();
    },

    _init_events: function () {
        $(window).load(function() {
            sales_partners_grid._resize_teaser();
            $('[data-section="sales_partners_grid"]').isotope('layout');
        });
        $(window).on('weisang_resize_ready', function() {
            sales_partners_grid._resize_teaser();
        });
    },

    _init_isotope: function () {
    	$(document).ready(function(){
            if ($('[data-section="sales_partners_grid"]').length) {
                $('[data-section="sales_partners_grid"]').isotope({
                    layoutMode: 'fitRows',
                    itemSelector: '[data-section="sales_partner_teaser_wrapper"]',
                    resizable: false,
                    fitRows: {
                        gutter: '.gutter-sizer'
                    },
                    filter: sales_partners_grid._filter_function()
                });

                $('[data-section="sales_partners_grid"]').on( 'arrangeComplete', function() {
                    sales_partners_grid._resize_teaser();
                });
            }
        });
    },

    _init_select_events: function () {
        $('.countries_select').on('change', function(){
            $('[data-section="sales_partners_grid"]').isotope({
                filter: sales_partners_grid._filter_function()
            });
        });
    },

    _filter_function: function () {

        // Check if "All Countries" is selected
        if ($('.countries_select').val() === "show_all") {
            return "*";
        }

        // Check if no countries with the countrycode is found and filter to "default"
        else if (!$('[data-section="sales_partners_grid"]').find("[data-country='" + $('.countries_select').val().toLowerCase() + "']").length) {
            return "[data-role='default']";
        } 

        // Filter according to countrycode  
        else {
            return "[data-country='" + $('.countries_select').val().toLowerCase() + "']";
        }
    },

    _resize_teaser: function () {

        // Recalculate height
        if (window.innerWidth > SCREEN_SIZE_XS_MAX) {

            // Loop through all weisang_sales_partners_grids on the page
            $(".weisang_sales_partners_grid").each(function() {

                // Find all teaser wrappers
                var $sales_partner_wrapper = $(this).find("[data-section='sales_partner_teaser_wrapper']:not([style*='display: none;'])"),
                    max_height_content = 0;

                // Get max height of teaser content in current grid
                $.each($sales_partner_wrapper ,function () {
                    var $teaser_content = $(this).find("[data-section='sales_partner']"),
                        content_height = $teaser_content.outerHeight();

                    if(max_height_content < content_height) {max_height_content = content_height;}
                });

                // Set teaser content height to max found height
                $(this).find("[data-section='sales_partner_teaser_wrapper']").css("height", max_height_content);
            });
        }
        else {
            // Reset wrapper height
            $("[data-section='sales_partner_teaser_wrapper']").css("height", "");
        }

        // Refresh isotope layout
        $("[data-section='sales_partners_grid']").one("webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend",
        function(event) {
            $('[data-section="sales_partners_grid"]').isotope('layout');
        });
    }
};

})(jQuery);