(function($) {


call_to_action_banner = {
    init: function () {
    	this._init_events();
    },

    _init_events: function () {
        $(window).load(function() {
            call_to_action_banner._set_image_padding();
        });
    	$(window).on('weisang_resize_ready', function() {
            call_to_action_banner._set_image_padding();
    	});

        $(".wpb_content_element.call_to_action_banner").on('click', function(event) {
            var href = "";
            
            if ($(this).find('a.cta_link').attr('href') !== undefined) {
                href = $(this).find('a.cta_link').attr('href');
                window.location.href = href;
            } else if ($(this).find('a.mail_link').attr('href') !== undefined) {
                $(this).find('a.mail_link').click();
            }
        });
    },

    _set_image_padding: function () {
        $(".wpb_content_element.call_to_action_banner [data-field='image']").css("padding-top", "");

        $(".wpb_content_element.call_to_action_banner [data-section='top']").each(function () {
            var col_text_height = $(this).find("[data-column-name='text']").height();
            var col_image = $(this).find("[data-column-name='image']");
            var image = $(this).find("[data-field='image']");
            var col_image_height = col_image.height();
            
            if (window.innerWidth > SCREEN_SIZE_XS_MAX && col_text_height > col_image_height) {
                image.css('padding-top', col_text_height - col_image_height + 'px');
            }        
        });
    },
};


})(jQuery); 