(function($) {


page_teaser = {
    init: function () {
    	this._init_events();
    },

    _init_events: function () {
        $(window).load(function() {
            page_teaser._resize_teaser();
        });
    	$(window).on('weisang_resize_ready', function() {
            page_teaser._resize_teaser();
    	});
    },

    _resize_teaser: function () {
   		var teasers_rows = [];

        // Reset CSS properties
        $(".page_teaser .header, .page_teaser [data-section='info_area']").css("height", "");
        $(".page_teaser [data-field='teaser_image']").css('position', '');

        // Recalc height
        if (window.innerWidth > SCREEN_SIZE_XS_MAX) {        

            // Loop through all rows on the page
            $(".vc_row, .training_grid .row").each(function() {

                // Find all FlexPro page teasers
                var $page_teaser = $(this).find(".page_teaser"),
                    max_height_header = 0, max_height_content = 0;

                // Get max height of teaser head and content in current row
                $.each($page_teaser ,function () {
                    var $header = $(this).find('.header'),
                        $teaser_content = $(this).find("[data-section='info_area']"),
                        header_height = $header.outerHeight(),
                        content_height = $teaser_content.outerHeight();

                    if(max_height_header < header_height) {max_height_header = header_height;}
                    if(max_height_content < content_height) {max_height_content = content_height;}
                });

                // Set teaser header and content height to max found height
                $(this).find(".page_teaser[data-style='flex_pro'] .header, .page_teaser[data-style='icon'] .header").css("height", max_height_header);
                $page_teaser.find('[data-section="info_area"]').css("height", max_height_content);
            });

            // Make image placeable at bottom
            $(".page_teaser [data-field='teaser_image']").css('position', 'absolute');

        }
    }
};


})(jQuery); 