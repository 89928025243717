(function($) {

//******************************************************************************
//* Constants
//******************************************************************************
SCREEN_SIZE_XS_MAX = 767;



//******************************************************************************
//* UAParser init
//******************************************************************************
uaparser = new UAParser();



//******************************************************************************
//* Browser and os detection
//******************************************************************************
weisang_browser_and_os_detection = {
    init: function () {
        this._detect_ie();
        this._detect_ios();
    },

    _detect_ie: function () {
        if (uaparser.getBrowser().name === "IE") {
            $('body').addClass('weisang_is_ie');
        }
        else {
            $('body').addClass('weisang_is_not_ie');
        }
    },

    _detect_ios: function () {
        if (uaparser.getOS().name === "iOS") {
            $('body').addClass('weisang_is_ios');
        }
        else {
            $('body').addClass('weisang_is_not_ios');
        }
    }
};



//******************************************************************************
//* Trigger "Resize ready" event
//******************************************************************************
weisang_resize_handling = {
    resize_timer: null,

    init: function () {
        $( window ).resize(function() {
            clearTimeout(weisang_resize_handling.resize_timer);
            weisang_resize_handling.resize_timer = setTimeout(function() {
                $( window ).trigger( "weisang_resize_ready" );
            }, 200);
        });
    }
};



//******************************************************************************
//* Related articles carousel
//******************************************************************************
weisang_related_articles_carousel = {

    init: function () {
        $(document).ready(function(){
            $('.related_articles_carousel').slick({
                speed: 1000,
                slidesToShow: 4,
                slidesToScroll: 4,
                responsive: [
                    {
                      breakpoint: 1400,
                      settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                        infinite: true,
                      }
                    },
                    {
                      breakpoint: 1024,
                      settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                      }
                    },
                    {
                      breakpoint: 725,
                      settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                      }
                    },
                    {
                      breakpoint: 480,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                      }
                    }
                    // You can unslick at a given breakpoint now by adding:
                    // settings: "unslick"
                    // instead of a settings object
                ]
            });
        });
    }
};



//******************************************************************************
//* Select2
//******************************************************************************
weisang_select2 = {
    init: function () {
        $(".countries_select").select2({
            sorter: function(data) {
                return data.sort(function (a, b) {
                    if (a.text > b.text) {
                        return 1;
                    }
                    if (a.text < b.text) {
                        return -1;
                    }
                    return 0;
                });
              }
        });
        $(".select2_with_search .gfield_select").select2();
        $(".select2_no_search .gfield_select").select2({
            minimumResultsForSearch: -1
        });
    }
};

//******************************************************************************
//* Scroll to selector
//******************************************************************************



weisang_scroll_to_anchor = {

    target_onload_from_url: null, // is set in init_instant

    init_instant: function () {
        this.target_onload_from_url = window.location.hash;
        window.location.hash = "";
    },

    init: function () {
        // Init events
        setTimeout(function() {
            weisang_scroll_to_anchor._init_elements();
            weisang_scroll_to_anchor._init_clickevents();
        }, 520);

        // Scroll to hash from url
        this._scroll_to_url_hash(500);
    },

    _init_elements: function () {
        $("main.main a[href^='#'], .navbar a[href*='#']").each(function () {
            var current_path_and_search = window.location.pathname + window.location.search;
            var href_without_domain_and_hash = $(this).attr("href").
                                        replace(window.location.host, "").
                                        replace("http://", "").
                                        replace("https://", "").
                                        replace("//", "").
                                        split("#")[0];

            if (current_path_and_search !== href_without_domain_and_hash) {
                return;
            }

            var target_id = $(this).attr("href").substring($(this).attr("href").indexOf("#")+1);
            $(this).attr("data-action", "scroll_to_anchor");
            $(this).attr("data-target-id", target_id);
        });
    },

    _init_clickevents: function () {
        $("[data-action='scroll_to_anchor']").click(function (event) {
            event.preventDefault();
            history.pushState({}, '', window.location.pathname + '#' + $(this).attr("data-target-id"));
            weisang_scroll_to_anchor._scroll_to_url_hash();
        });
    },

    _get_offset: function () {
        return - ($("nav.navbar").height() + 80);
    },

    _scroll_to_url_hash: function (delay) {
        if (window.location.hash > "" || this.target_onload_from_url) {
            delay = delay || 1;

            setTimeout(function () {
                $.smoothScroll({
                    speed: 1000,
                    scrollTarget: weisang_scroll_to_anchor.target_onload_from_url || window.location.hash,
                    offset: weisang_scroll_to_anchor._get_offset()
                });
                //window.location.hash = target.replace("#", '');
                history.replaceState(null, null, weisang_scroll_to_anchor.target_onload_from_url);
                weisang_scroll_to_anchor.target_onload_from_url = null;
            },delay);
            // this.target_onload_from_url = null;
        }
    }
};
weisang_scroll_to_anchor.init_instant();



//******************************************************************************
//* Overlay Interface
//******************************************************************************
weisang_overlay_interface = {
    current_hide_timeout: 0,

    init: function () {
        $('#scroll_up').on('click', function () {
            weisang_overlay_interface._scrollToTop();
        });

        $(document).on('scroll', function() {
            weisang_overlay_interface._refreshScrollUpVisibility();
        });
    },

    _scrollToTop: function () {
        $.smoothScroll({
            scrollTarget: '#'
        });
    },

    _refreshScrollUpVisibility: function() {
        var bodyScrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        if ( bodyScrollTop > 100) {
            $('#scroll_up').addClass('visible');
            clearTimeout(weisang_overlay_interface.current_hide_timeout);
            weisang_overlay_interface.current_hide_timeout = setTimeout(function () {
                $('#scroll_up').removeClass('visible');
            }, 3000);
        }
        else {
            $('#scroll_up').removeClass('visible');
        }
    },
};



//******************************************************************************
//* Essential Grid
//******************************************************************************
weisang_essential_grid = {
    init: function () {
        weisang_essential_grid._deep_linking();
    },

    _deep_linking: function() {
        $(document).ready(function() {
            var grid = jQuery('.esg-grid');

            setTimeout(function(){

                var url = window.location.href;

                if(!grid.length || url.search('#') === -1) { return; }

                var hash = url.split('#');
                hash = hash[hash.length - 1];

                if(!hash) { return; }

                hash = hash.toLowerCase().split(' ').join('').split('/').join('');

                if(grid.is(':visible')) {
                    jQuery('div[data-filter="filter-' + hash + '"').trigger('click');
                }

            }, 510);
        });
    }
};


//******************************************************************************
//* BBpress Image fix
//******************************************************************************
weisang_bbpress = {
    init: function () {
        weisang_bbpress._img_to_style();
    },

    _img_to_style: function() {
        $('#bbpress-forums img:not(.um-avatar)').addClass('no_responsive_handling');
    }
};


})(jQuery);