(function($) {

partners_carousel = {

    init: function () {
        $(document).ready(function(){
            $('.partners_carousel').slick({
                speed: 1000,
                slidesToShow: 6,
                slidesToScroll: 6,
                responsive: [
                	{
				      breakpoint: 1400,
				      settings: {
				        slidesToShow: 5,
				        slidesToScroll: 5,
				        infinite: true,
				      }
				    },
				    {
				      breakpoint: 1024,
				      settings: {
				        slidesToShow: 3,
				        slidesToScroll: 3,
				        infinite: true,
				      }
				    },
				    {
				      breakpoint: 600,
				      settings: {
				        slidesToShow: 2,
				        slidesToScroll: 2
				      }
				    },
				    {
				      breakpoint: 480,
				      settings: {
				        slidesToShow: 1,
				        slidesToScroll: 1
				      }
				    }
				]
            });
        });
    }
};

})(jQuery);