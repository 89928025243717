(function($) {

//******************************************************************************
//* Related articles carousel
//******************************************************************************
weisang_article_carousel = {

    init: function () {
        $(document).ready(function(){

            // Default carousel settings
            $('.article_carousel').slick({
                speed: 1000,
                slidesToShow: 5,
                slidesToScroll: 5,
                responsive: [
                    {
                        breakpoint: 1400,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 4,
                            infinite: true,
                        }
                    },
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                            infinite: true,
                        }
                    }
                ]
            });

            // Carousel settings for style big_teaser
            $('[data-style="big_teaser"] .article_carousel').slick('unslick');
            $('[data-style="big_teaser"] .article_carousel').slick({
                slidesToShow: 2,
                slidesToScroll: 2
            });

            weisang_article_carousel.set_big_teaser_height();
            weisang_article_carousel.slick_control();
        });

        $(window).on('weisang_resize_ready', function() {
            weisang_article_carousel.slick_control();
            setTimeout(function() {
                weisang_article_carousel.set_big_teaser_height();
            }, 300);
        });
    },

    // Function for unslicking and re-slicking carousel
    slick_control: function() {
        if ($(window).width() <= SCREEN_SIZE_XS_MAX) {

            if ($('.article_carousel').hasClass('slick-initialized')) {
                $('.article_carousel').slick('unslick');
            }
            return;

        } else if (!$('.article_carousel').hasClass('slick-initialized')) {
            return $('.article_carousel').slick({slidesToShow:2, slidesToScroll:2});
        }
    },

    set_big_teaser_height: function() {
        var $big_teaser_height = $('.weisang_article_grid[data-style="big_teaser"] .weisang_news_teaser_wrapper').outerHeight();
        $('[data-style="big_teaser"] [data-field="big_teaser"] .image').css('height',$big_teaser_height);         
    }
};

})(jQuery); 