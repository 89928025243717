(function($) {

//******************************************************************************
//* Language switch
//******************************************************************************
weisang_language_switch = {
    init: function () {        
    },

    _init_events: function () {
    },
};



//******************************************************************************
//* Navigation desktop
//******************************************************************************
weisang_nav_desktop = {
    mouseover_delay_timeout: null,
    nav_overlay_default_min_height: 380,    

    init: function () {
        this._init_events();
    },

    _init_events: function () {
        // Level 0 click
        $("li[data-level='0'][data-has-sub='true']").click(function (event) {
            // Click should not be propagated to body
            event.preventDefault();
            event.stopPropagation();

            // Get status and id of clicked element
            var cur = $(this);
            var is_selected = cur.hasClass("selected");
            var item_id = cur.attr("data-id");

            // Reset current status
            $("li[data-level='0']").removeClass("selected");
            $("[data-level='1'] .element").removeClass("selected");
            $("[data-level='1']").removeClass("visible");
            $("[data-level='2']").removeClass("visible");
            
            weisang_nav_desktop._refresh_overlay_height($(this));

            // Set new status if needed
            if (!is_selected) {   
                cur.addClass("selected");
                
                $("[data-level='1'][data-parent-id='" + item_id.toString() + "']").addClass("visible");

                // Select first level 1 element    
                var first_item = $("[data-level='1'][data-parent-id='" + item_id.toString() + "'] .element:first-child");
                first_item.addClass("selected");
                var first_item_id = first_item.attr('data-id');
                
                $("[data-level='2'][data-parent-id='" + first_item_id.toString() + "']").addClass("visible");
            }

            // Refresh overlay visibility
            weisang_nav_desktop._refresh_overlay_visibility();

            // Refresh cta visibility
            weisang_nav_desktop._refresh_cta_visibility();

        });

        // Level 1 click
        $(".elements_wrapper[data-level='1'] .element[data-has-children='true']").click(function (event) {
            // Click should not be propagated to body
            event.preventDefault();
            event.stopPropagation();            
        });

        // Level 1 mouseover
        $("[data-level='1'] .element").mouseover(function () {
            var this_buffer = $(this);

            weisang_nav_desktop.mouseover_delay_timeout = setTimeout(function(){ 
                // Get status and id of clicked element
                var cur = this_buffer;
                var item_id = cur.attr("data-id");

                // Reset current status
                $("[data-level='1'] .element").removeClass("selected");
                $("[data-level='2']").removeClass("visible");
                
                //$("[data-level='2'][data-parent-id='" + item_id.toString() + "']").addClass("visible");
                $("[data-level='2'][data-parent-id='" + item_id.toString() + "']").addClass("visible");

                // Set new status 
                cur.addClass("selected");

                // Refresh overlay visibility
                weisang_nav_desktop._refresh_overlay_visibility();

                // Refresh cta visibility
                weisang_nav_desktop._refresh_cta_visibility();
            }, 300);
        });

        // Level 2 mouseover
        $("[data-level='2']").mouseover(function () {
            clearTimeout(weisang_nav_desktop.mouseover_delay_timeout);
        });

        // Hide overlay on body click
        $("body").click(function () {
            weisang_nav_desktop._hide_overlay();
        });
    },

    _refresh_overlay_visibility: function () {
        if ($("li[data-level='0'].selected").length > 0) {
            $("[data-section='nav_overlay']").addClass("visible");
            $("li[data-level='0']").addClass("show_overlay");
        }
        else {
            $("[data-section='nav_overlay']").removeClass("visible");
            $("li[data-level='0']").removeClass("show_overlay");    
        }
    },

    _refresh_cta_visibility: function () {
        // Set cta visibility
        $("[data-section='cta']").removeClass("visible");
        var cta_id = $("[data-level='1'] .selected").attr('data-cta-id');
        if (cta_id) {
            $("[data-section='cta'][data-id='" + cta_id.toString() + "']").addClass("visible");
        }
    },

    _refresh_overlay_height: function ( $element ) {
        var default_height = weisang_nav_desktop.nav_overlay_default_min_height;
        var height = $element.outerHeight();
        
        // Gather level1 ids
        var level0_id = $element.attr('data-id');
        
        $('[data-section="nav_overlay"] .elements_wrapper[data-level="1"][data-parent-id="' + level0_id.toString() + '"]').find('.element').each( function () {
            var level1_id = $(this).attr('data-id');
            $('[data-section="nav_overlay"] .elements_wrapper[data-level="2"][data-parent-id="' + level1_id.toString() + '"]').each( function() {
                var current_height = $(this).outerHeight();
                if (current_height > height) {
                    height = current_height;
                }
            });
        });
        
        if (height > default_height) {
            $('[data-section="nav_overlay"]').css('min-height', height);
            $('[data-section="nav_overlay"] .elements_wrapper[data-level="1"]').css('min-height', height);

        } else if  (height < default_height){
            $('[data-section="nav_overlay"]').css('min-height', default_height);
            $('[data-section="nav_overlay"] .elements_wrapper[data-level="1"]').css('min-height', default_height);
        }
    },

    _hide_overlay: function () {
        $("li[data-level='0'].selected").removeClass("selected");
        weisang_nav_desktop._refresh_overlay_visibility();
    }
};



//******************************************************************************
//* Navigation mobile
//******************************************************************************
weisang_nav_mobile = {
	
    init: function () {        
        $("#mobile_nav").mmenu(
        	{ 
                extensions: ["pageshadow", "positioning", "fixedElements"],
                offCanvas: { 
                    pageSelector: "#main_wrapper",
                    position: "right",
                } 
            }
        );

        this._init_events();
    },

    _init_events: function () {
        // Change hamburger button status
        var api = $('#mobile_nav').data('mmenu');

        api.bind('opening', function () {
            // ios fixes
            if ($('body').hasClass('weisang_is_ios')) {

                // Fixes scroll up icon
                $('#scroll_up').css('display', 'none');
                
                // Fixes bg_fixed_image position
                if ($('[data-field="bg_fixed_image"]').hasClass('top_with_breadcrumbs')) {
                    $('[data-field="bg_fixed_image"]').addClass('top_ios_fix_with_breadcrumbs');
                } else {
                    $('[data-field="bg_fixed_image"]').addClass('top_ios_fix');
                }
            }
        });

        api.bind('opened', function () { 
            $("button.hamburger").addClass("is-active"); 
        });

        api.bind('closed', function () { 
            // ios fixes
            if ($('body').hasClass('weisang_is_ios')) {

                // Fixes scroll up icon
                $('#scroll_up').css('display', 'block');

                // Fixes bg_fixed_image position
                $("body").one("webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend",
                function(event) {
                    $('[data-field="bg_fixed_image"]').removeClass('top_ios_fix');
                    $('[data-field="bg_fixed_image"]').removeClass('top_ios_fix_with_breadcrumbs');
                });
            }
            $("button.hamburger").removeClass("is-active");
        });

        var width = $( window ).width();

        // Close on resize
        $( window ).resize(function() {
            if ($( window ).width() !== width ) {
                weisang_nav_mobile.close();
                width = $( window ).width();
            }
        });
    },

    open: function () {
        $("#mobile_nav").data( "mmenu" ).open();        
    },

    close: function () {
        $("#mobile_nav").data( "mmenu" ).close();        
    },

    toggle: function () {
        if($('#mobile_nav').hasClass('mm-opened')) {
            this.close();
        }
        else {
            this.open();
        }
    }

};

})(jQuery); 