(function($) {

testimonial_carousel = {

    init: function () {
        this._init_events();

        $(document).ready(function(){
            $('.testimonial_carousel').slick({
                speed: 1000
            });
        });
    },

    _init_events: function () {
	    $(window).load(function() {
	        testimonial_carousel._change_elements_order();
	    });

		$(window).on('weisang_resize_ready', function() {
	        testimonial_carousel._change_elements_order();
		});
    },

	/*
		For mobile devices set position of name and company below main testimonial with right side
		For medium and large devices restore default position
	*/
	_change_elements_order: function () {

		function swap_positions($el_before, $el_after) {
			$(".testimonial_carousel .slick-slide").each(function () {
            	$(this).find($el_after).insertBefore($(this).find($el_before));
            });
		}

	    if (window.innerWidth <= SCREEN_SIZE_XS_MAX) {
	    	swap_positions("[data-section='person']", "[data-section='opinion']");
        } else {
	    	swap_positions("[data-section='opinion']", "[data-section='person']");
        }
    }
};

})(jQuery);